// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/ArtistPage.js" /* webpackChunkName: "component---src-templates-artist-page-js" */),
  "component---src-templates-playlist-page-js": () => import("./../../../src/templates/PlaylistPage.js" /* webpackChunkName: "component---src-templates-playlist-page-js" */),
  "component---src-templates-track-page-js": () => import("./../../../src/templates/TrackPage.js" /* webpackChunkName: "component---src-templates-track-page-js" */)
}

