const theme = {
	text: '#fff',
	background: '#030926',
	panel: '#000000',
	links: '#0081ff',
	dimmedTextOpacity: 0.7,
	fonts: {
		default: '"Courier New", Courier, monospace',
		label: 'Impact Label Reversed, "Courier New", Courier, monospace',
	},
	typeScale: {
		xxs: '0.65rem',
		xs: '0.8rem',
		s: '0.9rem',
		m: '1rem',
		l: '1.4rem',
		xl: '1.7rem',
	},
	spacing: {
		xxs: '0.15rem',
		xs: '0.25rem',
		s: '0.5rem',
		m: '1rem',
		l: '1.5rem',
		xl: '2rem',
		xxl: '4rem',
	},
	breakpoints: {
		xs: '380px',
		s: '500px',
		m: '800px',
	},
}

export default theme
