const visuallyHidden = {
	position: 'absolute',
	overflow: 'hidden',
	width: 1,
	height: 1,
	padding: 0,
	clip: 'rect(0 0 0 0)',
	border: 0,
}

export default visuallyHidden
