import React, {forwardRef} from 'react'

import Svg from './Svg'

function MuteIcon({size, ...otherProps}, ref) {
	return (
		<Svg ref={ref} size={size} {...otherProps} viewBox="0 0 30 30">
			<path
				d="M-6169.556-988.393H-6174v-8h4.443l6.608-6.607v21.212Z"
				transform="translate(6178 1007.394)"
			/>
			<path
				d="M-6162-981h0v-3a7.008,7.008,0,0,0,7-7,7.007,7.007,0,0,0-7-7v-3a10.012,10.012,0,0,1,10,10A10.012,10.012,0,0,1-6162-981Zm0-5h0v-3a2,2,0,0,0,2-2,2,2,0,0,0-2-2v-3a5,5,0,0,1,5,5A5.006,5.006,0,0,1-6162-986Z"
				transform="translate(6179 1006)"
			/>
		</Svg>
	)
}

export default forwardRef(MuteIcon)
